import { ref, reactive } from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useStudentFinancialTransactions() {

    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]); //ref: to make it reactive
    const transactions = ref([]); //ref: to make it reactive
    const transaction = ref([]); //ref: to make it reactive
    const lists = reactive({});
    const student_balance = ref()
    const student = ref()
    const students = ref([])
    const dept_totals = ref([])

    const geteWalletQrCode = async (student_id = null) => {
        try {

            var url = '/api/student_transactions/ewallet_qrcode';

            if (student_id)
                url += '/' + student_id;

            let response = await axios.get(url);
            return response.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getTransactions = async (data = {}) => {
        try {
            let response = await axios.post('/api/student_transactions/get', data);
            transactions.value = response.data.data.transactions;
            lists.students = response.data.data.students;
            student_balance.value = response.data.data.student_balance;
            dept_totals.value = response.data.data.dept_totals;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getStudentsList = async () => {
        try {
            let response = await axios.get('/api/student_transactions/students');
            lists.students = response.data.data.students;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getParentStudentsList = async () => {
        try {
            let response = await axios.get('/api/student_transactions/parents/students');
            students.value = response.data.data.students;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getStudent = async (student_id) => {
        try {
            let response = await axios.get('/api/student_transactions/students/' + student_id);
            student.value = response.data.data.student;
            lists.categories = response.data.data.categories;
            student_balance.value = response.data.data.student_balance;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeTransaction = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/student_transactions/', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const storePurchase = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/student_transactions/student/purchase/store', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const deleteTransaction = async (id) => {
        try {
            await axios.delete('/api/student_transactions/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const chargeNow = async (data) => {
        try {
            let response = await axios.post('/api/student_transactions/parents/charge_ewallet', data);
            return response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    return {
        transactions,
        transaction,
        geteWalletQrCode,
        getStudentsList,
        getStudent,
        storeTransaction,
        getTransactions,
        deleteTransaction,
        storePurchase,
        getParentStudentsList,
        chargeNow,
        student_balance,
        dept_totals,
        lists,
        student,
        students,
        msgs,
        errors,
    }
}