<template>
    <div class="row">
        <div v-if="errors && errors.length > 0" class="col-md-12">
            <div class="box box-info">
                <div class="alert alert-danger">
                    <ul style="margin: 0">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                        <li v-for="error in errors" :key="error">{{error}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="msgs && msgs.length > 0" class="col-md-12">
           <div class="box box-info">
                <div class="alert alert-success">
                    <ul style="margin: 0">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                        <li v-for="msg in msgs" :key="msg">{{msg}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['errors', 'msgs', 'type'],
        name: 'FeedbackMessages',
    }
</script>