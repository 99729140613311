<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Purchase</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'eWallet' }">eWallet</router-link>
          </li>
          <li class="breadcrumb-item active">Purchase</li>
        </ol>
      </div>
    </div>
    <div class="row tab-content">
      <div id="list-view" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Student Balance: {{ student_balance }}</h4>
          </div>
          <div class="card-body" v-if="student">
            <div class="form-group row">
              <div class="col-md-4">
                <img src="" alt="" />
              </div>
              <div class="col-md-8">
                <p><strong>Name:</strong> {{ student.user.fullname }}</p>
                <p><strong>Code:</strong> {{ student.user.code }}</p>
                <p><strong>Year:</strong> {{ student.year.value }}</p>
                <p><strong>Level:</strong> {{ student.level.value }}</p>
                <p><strong>Class:</strong> {{ student.class.name }}</p>
              </div>
            </div>
            <feedback-messages :msgs="msgs" :errors="errors" />
            <form id="purchase_form" @submit.prevent="submitFormData()">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">
                  Amount <span style="color: red">*</span>
                </label>
                <div class="col-md-9">
                  <input
                    type="number"
                    name="amount"
                    class="form-control"
                    placeholder="Amount"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-form-label">
                  Category <span style="color: red">*</span>
                </label>
                <div class="col-md-9">
                  <select class="form-control" name="spent_at" required>
                    <option
                      v-for="(category, index) in lists.categories"
                      :key="index"
                      :value="index"
                    >
                      {{ category }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Comment</label>
                <div class="col-md-9">
                  <textarea class="form-control" name="comment"></textarea>
                </div>
              </div>
              <div
                class="form-group row"
                style="padding-top: 2rem; margin-bottom: 0px"
              >
                <div class="col-md-12">
                  <button
                    :disabled="disableBtn"
                    name="submit"
                    type="submit"
                    class="btn btn-primary"
                    value="Submit"
                    onclick="this.blur();"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useStudentFinancialTransactions from "@/composables/student_financial_transactions";
import { onMounted, inject, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    FeedbackMessages,
  },
  props: {
    student_id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_eWallet"))
        router.push({
          name: "401",
        });
    });

    const {
      lists,
      student,
      errors,
      msgs,
      student_balance,
      getStudent,
      storePurchase,
    } = useStudentFinancialTransactions();

    onMounted(() => {
      getStudent(props.student_id);
    });

    const submitFormData = async () => {
      disableBtn.value = true;
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var formDataObj = new FormData();
            const form = document.querySelector("#purchase_form");
            Array.from(form.elements).forEach((input) => {
              formDataObj.append(input.name, input.value);
            });

            formDataObj.append("student_id", props.student_id);
            formDataObj.append("type", "Deduct");

            swal.fire({
              title: "Please wait while submit your request",
              allowOutsideClick: false,
              didOpen: () => {
                swal.showLoading();
              },
            });

            storePurchase(formDataObj).then((resp) => {
              swal.close();
              if (resp != "fail") {
                swal.fire({
                  icon: "success",
                  title: "Your work has been saved",
                });

                document.getElementById("purchase_form").reset();
                disableBtn.value = false;
                getStudent(props.student_id);
              } else {
                swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
              }

              window.scrollTo(0, 0);
              disableBtn.value = false;
            });
          }
        });

        disableBtn.value = false;
    };

    return {
      errors,
      msgs,
      student,
      lists,
      disableBtn,
      student_balance,
      submitFormData,
    };
  },
};
</script>
